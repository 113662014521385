import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import meta from "./modules/meta";
import promo from "./modules/promo";
import onlineYoga from "./modules/onlineYoga";
import yoga from "./modules/yoga";
import category from "./modules/category";
import seminar from "./modules/seminar";
import alert from "./modules/alert";
import account from "./modules/account";
import invoice from "./modules/invoice";
import faq from "./modules/faq";
import contact from "@/store/modules/contact";
import subscription from "@/store/modules/subscription";
import setting from "@/store/modules/setting";

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        meta,
        promo,
        onlineYoga,
        yoga,
        category,
        seminar,
        alert,
        account,
        invoice,
        faq,
        contact,
        subscription,
        setting
    }
});

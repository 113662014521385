export default {
    namespaced: true,
    state: {
        items: []
    },
    getters: {},
    actions: {
        success: (store: any, name: string) => {
            store.dispatch("set", {
                type: "success",
                name: name
            });
        },
        warning: (store: any, name: string) => {
            store.dispatch("set", {
                type: "warning",
                name: name
            });
        },
        error: (store: any, name: string) => {
            store.dispatch("set", {
                type: "error",
                name: name
            });
        },
        set: (store: any, alert: any) => {
            const key =
                new Date().valueOf() + (Math.random() * (0 - 9999999) + 9999999);
            alert.key = key;
            store.commit("set", alert);
            setTimeout(() => {
                store.commit("delete", key);
            }, 3000);
        }
    },
    mutations: {
        set(state: any, alert: any) {
            state.items.push(alert);
        },
        delete(state: any, key: string) {
            state.items = state.items.filter((obj: any) => {
                return obj.key !== key;
            });
        }
    }
};

import api from "./../api";

const getHash = () => {
    const hash = localStorage.getItem("seminarHash");
    return hash === null ? "" : hash;
};

export default {
    namespaced: true,
    state: {
        seminar: null
    },
    getters: {},
    actions: {
        getPublic(store: any) {
            const hash = localStorage.getItem("seminarHash");
            return new Promise<void>((resolve, reject) => {
                api
                    .get("seminar/public" + (hash === null ? "" : "/" + hash))
                    .then((response: any) => {
                        store.state.seminar = response.data;
                        resolve(response.data);
                    })
                    .catch(() => {
                        reject(); // todo:
                    });
            });
        },
        login(store: any, data: any) {
            return api.post("seminar/login", data).then((response: any) => {
                localStorage.setItem("seminarHash", response.data.hash);
            });
        },
        publicContacts() {
            return api.get("seminar-attandee-contact/public-contacts/" + getHash());
        },
        addContact(store: any, data: any) {
            return api.post("seminar-attandee-contact/" + getHash(), data);
        },
        deleteContact(store: any, id: any) {
            return api.delete("seminar-attandee-contact/" + getHash() + "/" + id);
        }
    },
    mutations: {}
};

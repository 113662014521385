<template>
    <div class="main-menu d-flex">
        <div class="logo">
            <router-link :to="{ name: 'homepage' }" class="d-inline-block">
                <img src="/images/svg/LogoMTFY.svg" alt="MyTimeForYoga logo"/>
            </router-link>
        </div>
        <button
            type="button"
            class="btn btn-mobile-menu btn-light d-lg-none ml-auto"
            @click="openedOnMobile = !openedOnMobile"
        >
            <font-awesome-icon icon="bars"/>
        </button>
        <nav
            class="main-nav"
            :class="{ 'd-none d-lg-block': !openedOnMobile, opened: openedOnMobile }"
        >
            <div class="mobile-nav-header d-lg-none">
                <div class="logo">
                    <router-link :to="{ name: 'homepage' }" class="d-inline-block">
                        <img src="/images/svg/LogoMTFY.svg" alt="MyTimeForYoga logo"/>
                    </router-link>
                </div>
                <button
                    type="button"
                    class="btn btn-mobile-menu btn-light d-lg-none ml-auto"
                    @click="openedOnMobile = !openedOnMobile"
                >
                    <font-awesome-icon icon="times"/>
                </button>
            </div>
            <ul>
                <li>
                    <router-link :to="{ name: 'yogaList', params: { category: 'online' } }">
                        {{ t("Online yoga") }}
                    </router-link>
                </li>
                <li>
                    <router-link :to="{ name: 'yogaList', params: { category: 'kurzy' } }">
                        {{ t("Yoga courses") }}
                    </router-link>
                </li>
                <li>
                    <router-link :to="{ name: 'studio' }">
                        {{ t("Yoga studio") }}
                    </router-link>
                </li>
                <li>
                    <router-link :to="{ name: 'aboutMe' }">{{ t("O mne") }}</router-link>
                </li>
                <li v-if="account === null">
                    <router-link :to="{ name: 'login' }">{{ t("Prihlasenie") }}</router-link>
                </li>
                <li v-if="account === null">
                    <router-link :to="{ name: 'register' }" class="primary">{{ t("Register") }}</router-link>
                </li>
                <template v-if="account !== null">
                    <li>
                        <router-link :to="{ name: 'account' }" class="primary">
                            {{ account.fullName }}
                        </router-link>
                        <ul>
                            <li>
                                <router-link :to="{ name: 'account' }">
                                    {{ t("Profile") }}
                                </router-link>
                            </li>
                            <li>
                                <router-link :to="{ name: 'changePassword' }">
                                    {{ t("Change password") }}
                                </router-link>
                            </li>
                            <li>
                                <router-link :to="{ name: 'subscription' }">
                                    {{ t("Subscription") }}
                                </router-link>
                            </li>
                            <li>
                                <router-link :to="{ name: 'invoices' }">
                                    {{ t("Invoices") }}
                                </router-link>
                            </li>
                            <li>
                                <div @click="logout()">
                                    {{ t("Logout") }}
                                </div>
                            </li>
                        </ul>
                    </li>
                </template>
            </ul>
        </nav>
    </div>
</template>

<script>
export default {
    name: "MainMenu",
    computed: {
        account() {
            return this.$store.state.account.account;
        }
    },
    watch: {
        "$route.path"() {
            this.openedOnMobile = false;
        }
    },
    methods: {
        logout() {
            this.$store.dispatch("account/logout").then(() => {
                this.$router.push({name: "homepage"});
            });
        }
    },
    data() {
        return {
            openedOnMobile: false
        };
    }
};
</script>

<style lang="scss" scoped>
.main-menu {
    flex-direction: row;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 45px;

    @include media-breakpoint-down(md) {
        margin-bottom: 25px;
    }

    .btn-mobile-menu {
        width: 40px;
        height: 40px;
        padding: 0;
        line-height: 40px;
        text-align: center;
        border-radius: 50%;
        border: 1px solid var(--button-background-hover);
        background: var(--button-background-hover);
        color: var(--button-text-hover);
    }

    .logo {
        display: block;
        margin-right: 10px;

        img {
            width: 150px;

            @include media-breakpoint-down(md) {
                width: 120px;
            }
        }
    }

    .main-nav {
        flex: 1;

        @include media-breakpoint-down(md) {
            &.opened {
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: linear-gradient(180deg, rgba(243, 232, 231, 1) 0%, rgb(255, 255, 255) 20%);
            }

            .mobile-nav-header {
                display: flex;
                flex-direction: row;
                padding: 15px 15px 10px 15px;
                align-items: center;
            }

            background: $primaryBg;
            z-index: 100;

            > ul > li {
                border-top: 1px dashed var(--button-background);
            }

            ul {
                list-style: none;
                padding: 0;

                > li {
                    display: block;
                    padding: 10px 20px;


                    a {
                        color: $mainText;

                        img {
                            max-height: 20px;
                        }
                    }

                    ul {
                        margin-top: 10px;
                        margin-left: 10px;

                        li {
                            padding: 5px 0;

                            div {
                                display: inline-block;
                                margin-left: 5px;
                            }

                            &:before {
                                content: "\2022";
                                font-size: 18px;
                            }
                        }
                    }
                }
            }
        }

        @include media-breakpoint-up(lg) {
            > ul {
                list-style: none;
                padding: 0;
                margin: 0;
                text-align: right;

                > li {
                    position: relative;
                    display: inline-block;

                    > a {
                        padding: 15px 20px;
                        margin-right: 2px;
                        border-radius: 10px;
                        color: $mainText;
                        font-size: 16px;
                        text-decoration: none;

                        &.primary {
                            background: var(--button-background);
                            color: var(--button-text);
                        }

                        &.router-link-active,
                        &:hover {
                            background: var(--page-background);
                            color: var(--button-text-hover);
                        }

                        .icon {
                            height: 20px;
                            margin-top: -5px;
                        }
                    }

                    > ul {
                        position: absolute;
                        min-width: 200px;
                        background: var(--page-background);
                        top: 100%;
                        right: 0;
                        padding: 2px 10px;
                        z-index: 10;
                        border: 1px solid var(--button-background);
                        list-style: none;
                        display: none;
                        border-radius: 10px;
                        margin-top: 6px;
                        box-shadow: 0 0.375rem 1.5rem 0 rgba(140,152,164,.125);

                        > li {
                            display: block;
                            text-align: left;

                            &:not(:last-child) {
                                border-bottom: 1px solid var(--button-background);
                            }

                            > div,
                            > a {
                                display: block;
                                padding: 9px 0;
                                color: $mainText;
                                cursor: pointer;

                                &.router-link-active,
                                &:hover {
                                    color: $hoverText;
                                }
                            }
                        }
                    }

                    &:hover > ul {
                        display: block;
                    }
                }
            }
        }
    }
}
</style>

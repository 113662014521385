<template>
    <div class="main-footer-wrapper">
        <div class="row">
            <div class="col-md-3 d-none d-md-flex justify-content-center align-items-center">
                <img src="/images/svg/LogoMTFY.svg" alt="Podpis" class="signature">
            </div>
            <div class="col-md-3">
                <div class="footer-heading">MyTimeForYoga</div>
                <ul class="footer-list">
                    <li>
                        <router-link :to="{ name: 'aboutMe' }">
                            {{ t("About me") }}
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="{ name: 'faq' }">
                            {{ t("FAQ") }}
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="{ name: 'aboutMe' }">
                            {{ t("Contacts") }}
                        </router-link>
                    </li>
                </ul>
            </div>
            <div class="col-md-3">
                <div class="footer-heading">{{ t('Social networks') }}</div>
                <ul class="socials-list">
                    <li>
                        <a href="https://www.instagram.com/my_time_for_yoga/" target="_blank">
                            <font-awesome-icon :icon="['fab', 'instagram']"/>
                            {{ t("Instagram") }}
                        </a>
                    </li>
                    <li>
                        <a href="#" target="_blank">
                            <font-awesome-icon :icon="['fab', 'tiktok']"/>
                            {{ t("TikTok") }}
                        </a>
                    </li>
                    <li>
                        <a href="#" target="_blank">
                            <font-awesome-icon :icon="['fab', 'youtube']"/>
                            {{ t("Youtube") }}
                        </a>
                    </li>

                </ul>
            </div>
            <div class="col-md-3">
                <div class="footer-heading">{{ t('Yoga') }}</div>
                <ul class="footer-list">
                    <li>
                        <router-link
                            :to="{ name: 'yogaVideo', params: {category: 'kurzy', slug: 'yoga-basics-1655664877'} }">
                            {{ t("How to start") }}
                        </router-link>
                    </li>
                    <li>
                        <a href="https://ayurvedakarin.com/skupinove-pobyty/" target="_blank">
                            {{ t("Equipment") }}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="footer-text">
            {{ t("footer-copyrights").replace("%year%", year) }}

            <ul class="footer-nav">
                <li>
                    <router-link :to="{ name: 'termsOfUse' }">
                        {{ t("OBCHODNÉ PODMIENKY") }}
                    </router-link>
                </li>
                <li>
                    <router-link :to="{ name: 'gdpr' }">
                        {{ t("GDPR") }}
                    </router-link>
                </li>
                <li>
                    <router-link :to="{ name: 'returnPolicy' }">
                        {{ t("Return policy") }}
                    </router-link>
                </li>
                <li v-if="account !== null && account.developer === true">
                    <span @click="$store.dispatch('translate/pushStrings')">
                      {{ t("Load strings") }}
                    </span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: "MainFooter",
    computed: {
        account() {
            return this.$store.state.account.account;
        },
        year() {
            return new Date().getFullYear();
        }
    }
};
</script>

<style lang="scss" scoped>
.main-footer-wrapper {
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    color: #2d374b;
    margin-top: 140px;
    padding-top: 50px;

    background: linear-gradient(180deg, rgba(243, 232, 231, 0.6) 0%, rgb(255, 255, 255) 40%);
    border-radius: 15px;

    @include media-breakpoint-down(md) {
        margin-top: 80px;
        padding-top: 22px;
    }

    a {
        color: #2d374b;

        &:hover {
            color: var(--hover-text);
        }
    }

    .signature {
        height: 60px;
    }

    .footer-text {
        font-size: 13px;
        border-top: 1px solid var(--button-background);;
        margin-top: 30px;
        display: flex;
        justify-content: center;
        opacity: 0.8;
        padding: 10px 0 20px;

        @include media-breakpoint-down(md) {
            flex-direction: column;
            padding-left: 20px;
        }
    }

    .footer-heading {
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 5px;

        @include media-breakpoint-down(md) {
            padding-left: 20px;
        }
    }

    .footer-list {
        padding-left: 18px;

        @include media-breakpoint-down(md) {
            padding-left: 38px;
        }

        a {
            text-decoration: none;
        }
    }

    .socials-list {
        padding-left: 0;
        list-style: none;

        @include media-breakpoint-down(md) {
            padding-left: 20px;
        }

        svg {
            width: 18px;
        }

        a {
            text-decoration: none;
        }
    }

    .footer-nav {
        padding: 0;
        margin: 0;
        display: inline-block;

        @include media-breakpoint-down(md) {
            display: block;
        }

        li {
            display: inline-block;
            margin-left: 20px;

            @include media-breakpoint-down(md) {
                display: block;
                margin-left: 0;
            }
        }
    }
}
</style>

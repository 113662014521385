import api from "./../api";

export default {
    namespaced: true,
    state: {
        faqs: []
    },
    getters: {},
    actions: {
        list(store: any) {
            api
                .post("faq/list/1/100")
                .then((response: any) => {
                    store.state.faqs = response.data.items;
                })
                .catch(() => {
                    store.state.faqs = null;
                });
        }
    },
    mutations: {}
};


import {Component, Vue} from "vue-property-decorator";
import "./assets/styles/template.scss";
import Alert from "@/components/Alert.vue";
import MainMenu from "@/components/MainMenu.vue";
import MainFooter from "@/components/MainFooter.vue";
// @ts-ignore
import MainMixin from "./MainMixin";

@Component({
    components: {
        MainFooter,
        MainMenu,
        Alert
    },
    mixins: [MainMixin],
    mounted() {
        this.$store.dispatch("setting/load");
    }
})
export default class App extends Vue {
}

import api from "./../api";

export default {
    namespaced: true,
    state: {},
    getters: {},
    actions: {
        send(store: any, data: any) {
            return api.post("contact/send", data);
        }
    },
    mutations: {}
};

import api from "./../api";

export default {
    namespaced: true,
    state: {
        nextYogaLoading: true,
        nextYoga: null,
        myYogaLoading: true,
        myYoga: null
    },
    getters: {},
    actions: {
        my(store: any) {
            store.state.myYogaLoading = true;
            api
                .get("online-yoga/my")
                .then((response: any) => {
                    store.state.myYoga = response.data;
                    store.state.myYogaLoading = false;
                })
                .catch(() => {
                    store.state.myYoga = null;
                    store.state.myYogaLoading = false;
                });
        },
        next(store: any) {
            store.state.nextYogaLoading = true;
            api
                .get("online-yoga/next")
                .then((response: any) => {
                    store.state.nextYoga = response.data;
                    store.state.nextYogaLoading = false;
                })
                .catch(() => {
                    store.state.nextYoga = null;
                    store.state.nextYogaLoading = false;
                });
        },
        signIn(store: any, id: number) {
            store.state.nextYogaLoading = true;
            api
                .post("online-yoga/sign-in/" + id)
                .then(() => {
                    store.dispatch("next");
                })
                .catch(() => {
                    store.state.nextYogaLoading = false;
                });
        }
    },
    mutations: {}
};

import api from "./../api";

export default {
    namespaced: true,
    state: {
        course: null,
        freeCourse: null,
        shoppingCart: null,
        yoga: null,
        videos: null,
        favs: [],
        favLoading: true,
        courseList: [],
        loadingCourses: true,
        loading: true
    },
    getters: {},
    actions: {
        shoppingCart(store: any, slug: string) {
            store.state.shoppingCart = null;
            api.get("yoga/shopping-cart/" + slug).then((response: any) => {
                store.state.shoppingCart = response.data;
            });
        },
        detail(store: any, slug: string) {
            store.state.yoga = null;
            store.state.favs = [];
            api.get("yoga/slug/" + slug).then((response: any) => {
                store.state.yoga = response.data;
                store.state.favs = response.data.fav;
            });
        },
        course(store: any) {
            store.state.course = null;
            api.get("yoga/course").then((response: any) => {
                store.state.course = response.data;
            });
        },
        freeCourse(store: any) {
            store.state.course = null;
            api.get("yoga/free-course").then((response: any) => {
                store.state.freeCourse = response.data;
            });
        },
        list(store: any, data: any) {
            store.state.loading = true;
            store.state.videos = [];
            store.state.favs = [];

            const length = data.length === null ? '' : '&length=' + data.length

            api
                .post("yoga/category/?category=" + data.category + length)
                .then((response: any) => {
                    store.state.videos = response.data.videos;
                    store.state.favs = response.data.favs;
                    store.state.loading = false;
                })
                .catch(() => {
                });
        },
        toggleFav(store: any, id: number) {
            return new Promise<void>((resolve, reject) => {
                api
                    .post("yoga/toggle-fav/" + id)
                    .then((response: any) => {
                        response.data.state === false
                            ? store.commit("removeFav", id)
                            : store.commit("addFav", id);
                        resolve(response.data);
                    })
                    .catch(() => {
                        reject();
                    });
            });
        },
        courses(store: any) {
            store.state.loadingCourses = true;
            store.state.courseList = [];
            store.state.favs = [];
            api.post("yoga/courses").then((response: any) => {
                store.state.loadingCourses = false;
                store.state.courseList = response.data.videos;
                store.state.favs = response.data.favs;
            });
        }
    },
    mutations: {
        removeFav(state: any, id: number) {
            const index: any = state.favs.indexOf(id);
            if (index > -1) {
                state.favs.splice(index, 1); // 2nd parameter means remove one item only
            }
        },
        addFav(state: any, id: number) {
            state.favs.push(id);
        }
    }
};

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// @ts-ignore
import VueChart from "vue-chart-js";
import {formatDate, formatDateTime, shortDateTime} from "@/utils";

const Translator = require("vue-titan-translator");
import messages from "./locales.json";

Vue.config.productionTip = false;

// boostrap
import {VBTooltip, DropdownPlugin, ModalPlugin} from "bootstrap-vue";

Vue.use(DropdownPlugin);
Vue.use(ModalPlugin);
Vue.directive("b-tooltip", VBTooltip);

Vue.use(VueChart);

// Textarea
// @ts-ignore
import VueTextareaAutosize from "vue-textarea-autosize";

Vue.use(VueTextareaAutosize);

// Font awesome
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {library} from "@fortawesome/fontawesome-svg-core";
import {
    faSignOutAlt,
    faPlusCircle,
    faUserCircle,
    faChevronLeft,
    faPlus,
    faSpinner,
    faBars,
    faFilm,
    faFireAlt,
    faSpa,
    faCheckCircle,
    faLock,
} from "@fortawesome/free-solid-svg-icons";
import {
    faStar,
    faClock,
    faSun,
} from "@fortawesome/free-regular-svg-icons";
import {
    faInstagram,
    faYoutube,
    faTiktok
} from "@fortawesome/free-brands-svg-icons";
import {
    faAt,
    faPhone,
    faCheck,
    faTimes,
    faExclamation,
    faSearch,
    faArrowRight,
    faArrowLeft,
    faTrashAlt,
    faAngleDown,
    faAngleUp,
    faHeart,
    faImages,
    faPlay

} from "@fortawesome/free-solid-svg-icons";
import {apiLink} from "@/store/api";

library.add(
    faSignOutAlt,
    faPlusCircle,
    faUserCircle,
    faChevronLeft,
    faPlus,
    faAt,
    faPhone,
    faBars,
    faStar,
    faClock,
    faHeart,
    faSun,
    faCheck,
    faTimes,
    faExclamation,
    faSearch,
    faArrowRight,
    faArrowLeft,
    faSpinner,
    faTrashAlt,
    faAngleDown,
    faAngleUp,
    faFilm,
    faFireAlt,
    faSpa,
    faImages,
    faCheckCircle,
    faPlay,
    faInstagram,
    faYoutube,
    faTiktok,
    faLock,
);
Vue.component("font-awesome-icon", FontAwesomeIcon);

const translatorMessages = () => {
    return process.env.VUE_APP_DB_TRANSLATES === "1" ? {} : messages;
};

Translator.default.install(Vue, {
    store,
    apiUrl: apiLink(),
    flagPath: "/flags/4x3/{lang}.svg",
    languages: {
        FE: [
            {
                key: "sk",
                name: "Slovenčina",
                default: true
            }
        ]
    },
    type: "FE"
});

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app");

export const formatNumber = (
    number: any,
    minimumFractionDigits: any = 2
): string => {
    return Number(number).toLocaleString("sk-SK", {
        minimumFractionDigits: minimumFractionDigits,
        maximumFractionDigits: minimumFractionDigits
    });
};

Vue.filter("date", (date: any) => {
    return formatDate(date);
});
Vue.filter("dateTime", (date: any) => {
    return formatDateTime(date);
});
Vue.filter("shortDateTime", (date: any) => {
    return shortDateTime(date);
});
Vue.filter("number", formatNumber);

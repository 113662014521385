import api from "./../api";

export default {
    namespaced: true,
    state: {
        loadingSubscriptions: true,
        subscriptions: [],
        available: [],
        yoga: {}
    },
    getters: {},
    actions: {
        available(store: any) {
            store.state.available = [];
            api.get("subscription/available").then((response: any) => {
                store.state.available = response.data;
            });
        },
        active(store: any) {
            store.state.loadingSubscriptions = true;
            store.state.subscriptions = null;
            store.state.yoga = {};
            api.get("subscription/all").then((response: any) => {
                store.state.subscriptions = response.data.subscriptions;
                response.data.yoga.forEach((yoga: any) => {
                    store.state.yoga[yoga.stripeProduct] = yoga;
                });
                store.state.loadingSubscriptions = false;
            });
        },
        cancel(store: any, subscriptionId: string) {
            api.post("subscription/cancel/" + subscriptionId).then(response => {
            });
        }
    },
    mutations: {}
};

import {format, parseISO} from "date-fns";

export const formatPrice = (
    number: number,
    minimumFractionDigits: number | null = 2
) => {
    const options =
        minimumFractionDigits === null ? {} : {minimumFractionDigits};
    return Number(number).toLocaleString("sk-SK", options);
};

export const formatDate = (date: string) => {
    return format(parseISO(date), "dd.MM.yyyy");
};

export const formatDateTime = (date: string) => {
    return format(parseISO(date), "dd.MM.yyyy HH:mm:ss");
};

export const shortDateTime = (date: string) => {
    return (
        format(parseISO(date), "d.M.") + " o " + format(parseISO(date), "HH:mm")
    );
};

export const validateForm = (form: any) => {
    form.validate();
    return form.errors.length === 0;
};

export const randomString = (length: number): string => {
    let result = "";
    const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
};

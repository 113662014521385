<template>
    <div class="titan-alerts">
        <div
            class="titan-alert"
            :class="'titan-alert-' + alert.type"
            v-for="alert in alerts"
        >
            <div class="titan-alert-icon">
                <font-awesome-icon icon="check" v-if="alert.type === 'success'"/>
                <font-awesome-icon icon="times" v-if="alert.type === 'error'"/>
                <font-awesome-icon icon="exclamation" v-if="alert.type === 'warning'"/>
            </div>
            <div class="titan-alert-text secondary-font">{{ t(alert.name) }}</div>
            <font-awesome-icon
                icon="times"
                class="titan-alert-dismiss"
                @click="$store.commit('alert/delete', alert.key)"
            />
        </div>
    </div>
</template>
<script>
export default {
    name: "Alert",
    computed: {
        alerts() {
            return this.$store.state.alert.items;
        }
    }
};
</script>
<style lang="scss">
$success: $borderColor;
$error: #df3545;
$warning: #ffc107;

.titan-alerts {
    position: fixed;
    top: 20px;
    z-index: 9999;
    width: 340px;
    left: 50%;
    margin-left: -170px;

    .titan-alert {
        background: #fff;
        position: relative;
        box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1);
        margin-bottom: 10px;

        .titan-alert-text {
            font-size: 16px;
            line-height: 16px;
        }

        .titan-alert-dismiss {
            position: absolute;
            top: 50%;
            right: 10px;
            width: auto;
            height: 12px;
            margin-top: -6px;
            color: #989898;
        }

        .titan-alert-icon {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            width: 45px;
            background: $success;
            color: #fff;

            svg {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                cursor: pointer;
            }
        }

        &.titan-alert-error .titan-alert-icon {
            background: $error;
        }

        &.titan-alert-warning .titan-alert-icon {
            background: $warning;
        }

        .titan-alert-text {
            padding: 18px 23px 18px 60px;
        }
    }
}
</style>

import api from "./../api";

export default {
    namespaced: true,
    state: {
        setting: null
    },
    getters: {},
    actions: {
        load(store: any) {
            api
                .get("setting/1")
                .then((response: any) => {
                    store.state.setting = response.data;
                })
                .catch(() => {
                    store.state.setting = null;
                });
        }
    },
    mutations: {}
};

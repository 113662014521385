import api, {csrfKey} from "../api";

export default {
    namespaced: true,
    state: {
        account: null
    },
    getters: {
        account(state: any, getters: any) {
            return state.account;
        }
    },
    actions: {
        login(store: any, params: any) {
            return new Promise((resolve, reject) => {
                api
                    .post("account/login", params)
                    .then((response: any) => {
                        localStorage.setItem(csrfKey, response.data.csrf);
                        store.dispatch("get");
                        resolve(response.data);
                    })
                    .catch(error => {
                        reject(error.response);
                        store.dispatch("alert/error", error.response.data.status, {
                            root: true
                        });
                    });
            });
        },
        checkLogin(store: any, params: any) {
            return new Promise((resolve, reject) => {
                api
                    .post("account/check-login", params)
                    .then(response => {
                        resolve(true);
                    })
                    .catch(error => {
                        resolve(false);
                    });
            });
        },
        register(store: any, params: any) {
            return new Promise((resolve, reject) => {
                api
                    .post("account/register", params)
                    .then((response: any) => {
                        localStorage.setItem(csrfKey, response.data.csrf);
                        resolve(response.data);
                    })
                    .catch(error => {
                        reject(error.response);
                        store.dispatch("alert/error", error.response.data.status, {
                            root: true
                        });
                    });
            });
        },
        get(store: any) {
            return new Promise((resolve, reject) => {
                api
                    .get("account")
                    .then((response: any) => {
                        if (response.data.status === undefined) {
                            store.commit("setProfile", response.data.account);
                            resolve(response.data);
                        } else {
                            reject(response);
                        }
                    })
                    .catch(error => {
                        reject(error.response);
                    });
            });
        },
        stats(store: any) {
            return new Promise((resolve, reject) => {
                api
                    .get("account/stats")
                    .then((response: any) => {
                        resolve(response.data);
                    })
                    .catch(error => {
                        reject(error.response);
                    });
            });
        },
        logout(store: any) {
            return new Promise((resolve, reject) => {
                api
                    .post("account/logout")
                    .then((response: any) => {
                        localStorage.removeItem(csrfKey);
                        store.commit("setProfile", null);
                        resolve(response.data);
                    })
                    .catch(error => {
                        reject(error.response);
                    });
            });
        },
        changePassword(store: any, params: any) {
            return new Promise((resolve, reject) => {
                api
                    .put("account/change-password", params)
                    .then((response: any) => {
                        store.dispatch("alert/success", "Password was changed", {
                            root: true
                        });
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error.response);
                        store.dispatch("alert/error", error.response.data.status, {
                            root: true
                        });
                    });
            });
        },
        updateProfile(store: any, params: any) {
            return new Promise((resolve, reject) => {
                api
                    .put("account/update-profile", params)
                    .then((response: any) => {
                        store.dispatch("alert/success", "Profile was updated", {
                            root: true
                        });
                        store.dispatch("get");
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error.response);
                        store.dispatch("alert/error", error.response.data.status, {
                            root: true
                        });
                    });
            });
        },
        lostPassword(store: any, params: any) {
            return new Promise((resolve, reject) => {
                api
                    .post("account/lost-password", params)
                    .then((response: any) => {
                        // store.dispatch('alert/success', 'Please check your e-mail address', {root: true});
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error.response);
                        store.dispatch("alert/error", error.response.data.status, {
                            root: true
                        });
                    });
            });
        },
        validateToken(store: any, params: any) {
            return api.get(
                "account/get-lost-password/" + params.id + "/" + params.hash
            );
        },
        resetPassword(store: any, params: any) {
            return new Promise((resolve, reject) => {
                api
                    .put(
                        "account/reset-password/" + params.id + "/" + params.hash,
                        params
                    )
                    .then(response => {
                        // store.dispatch('alert/success', 'Password changed, please login', {root: true});
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error.response);
                        store.dispatch("alert/error", error.response.data.status, {
                            root: true
                        });
                    });
            });
        }
    },
    mutations: {
        setProfile(state: any, data: any) {
            state.account = data;
        }
    }
};

import api from "./../api";

export default {
    namespaced: true,
    state: {
        items: [],
    },
    getters: {},
    actions: {
        list(store) {
            api.get("promo/available")
                .then((response: any) => {
                    store.state.items = response.data.items;
                })
                .catch(() => {
                    store.state.items = null;
                });
        },
    },
    mutations: {}
};

import axios from "axios/index";

export const csrfKey = process.env.VUE_APP_API_CSRF || "csrf";

export const apiLink = () => {
    return process.env.VUE_APP_API_LINK;
};

import router from "@/router";

export const vimeoUrl = (videoId: any, autoplay = 1) => {
    return (
        "https://player.vimeo.com/video/" +
        videoId +
        "?autoplay=" +
        autoplay +
        "&h=952eaa7a2e"
    );
};

export default {
    getAxiosConfig() {
        axios.defaults.headers.common["Authorization"] = localStorage.getItem(
            csrfKey
        );
        axios.interceptors.response.use(
            response => response,
            error => {
                const routeName: any = router.currentRoute.name;

                if (error.response.data.status === "No active subscription") {
                    if (["liveYoga"].includes(routeName)) {
                        router.push({
                            name: "inactiveSubscription",
                            params: {slug: "live"}
                        });
                    } else {
                        router.push({
                            name: "inactiveSubscription",
                            params: {slug: "online"}
                        });
                    }
                } else {
                    throw error;
                }

                return error.response;
            }
        );

        return {
            withCredentials: true,
            params: {}
        };
    },

    get(link: string) {
        return this.handleRequest(
            axios.get(apiLink() + link, this.getAxiosConfig())
        );
    },

    post(link: string, data = {}) {
        return this.handleRequest(
            axios.post(apiLink() + link, data, this.getAxiosConfig())
        );
    },

    put(link: string, data = {}) {
        return this.handleRequest(
            axios.put(apiLink() + link, data, this.getAxiosConfig())
        );
    },

    delete(link: string) {
        return this.handleRequest(
            axios.delete(apiLink() + link, this.getAxiosConfig())
        );
    },

    handleRequest(endpointCall: any) {
        return new Promise((resolve, reject) => {
            endpointCall
                .then((response: any) => {
                    if (response.status === 200 || response.status === 201) {
                        resolve(response);
                    } else {
                        reject({error: response});
                    }
                })
                .catch((error: any) => {
                    reject(error);
                });
        });
    }
};

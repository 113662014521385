import {Route} from "vue-router";

export const setMeta = (route: Route, store: any, t: any) => {
    store.commit(
        "meta/setTitle",
        t("seo-title-" + route.name) + " - " + t("MyTimeForYoga")
    );
    store.commit("meta/setDescription", t("seo-description-" + route.name));
};

export default {
    namespaced: true,
    state: {
        title: "",
        description: ""
    },
    getters: {},
    actions: {},
    mutations: {
        setTitle(state: any, string: string) {
            document.title = string;
        },
        setDescription(state: any, string: string) {
            const element = document.querySelector('meta[name="description"]');
            if (element !== null) {
                element.setAttribute("content", string);
            }
        }
    }
};

import {setMeta} from "@/store/modules/meta";

export default {
    computed: {
        locked() {
            return false;
            // return localStorage.getItem("access") !== "1";
        }
    },
    mounted() {
        this.$store.dispatch("account/get").catch(() => {
        });
        this.setSeo();

        if (
            this.locked === true &&
            ["/coskoro", "/coskoro/ja-mozem"].includes(window.location.pathname) ===
            false
        ) {
            this.$router.push({name: "comingSoon"});
        }
    },
    watch: {
        $route() {
            this.setSeo();
        },
        "$store.state.translate.loading"() {
            this.setSeo();
        }
    },
    methods: {
        setSeo() {
            setMeta(this.$route, this.$store, this.t);
        }
    }
};

import api from "./../api";

export default {
    namespaced: true,
    state: {
        loadingCategories: true,
        category: null,
        categories: []
    },
    getters: {},
    actions: {
        list: function (store: any) {
            api
                .get("category/public-list")
                .then((response: any): void => {
                    store.state.categories = response.data;
                    store.state.loadingCategories = false;
                })
                .catch(() => {
                    store.state.categories = [];
                    store.state.loadingCategories = false;
                });
        },
        detail(store: any, slug: string) {
            store.state.category = null;
            api.get("category/slug/" + slug).then((response: any) => {
                store.state.category = response.data;
            });
        }
    },
    mutations: {}
};

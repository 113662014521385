import Vue from "vue";
import VueRouter, {RouteConfig} from "vue-router";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: "",
        name: "homepage",
        component: () => import("../views/Homepage.vue")
    },
    {
        path: "/studio",
        name: "studio",
        component: () => import("../views/studio/StudioPage.vue")
    },
    {
        path: "/kupit/:slug",
        component: () => import("../views/shopping-cart/ShoppingCartWrapper.vue"),
        children: [
            {
                path: "",
                name: "shoppingCart",
                component: () => import("../views/shopping-cart/ShoppingCart.vue")
            },
            {
                path: "zaplatene",
                name: "shoppingCartSuccess",
                component: () =>
                    import("../views/shopping-cart/ShoppingCartSuccess.vue")
            },
            {
                path: "zlyhala-platba",
                name: "shoppingCartFailed",
                component: () => import("../views/shopping-cart/ShoppingCartFailed.vue")
            }
        ]
    },
    {
        path: "/yoga",
        component: () => import("../views/yoga/YogaWrapper.vue"),
        children: [
            {
                path: "/yoga-:category",
                name: "yogaList",
                component: () => import("../views/yoga/YogaList.vue")
            },
            {
                path: ":category/:slug",
                name: "yogaVideo",
                component: () => import("../views/yoga/YogaVideo.vue")
            }
        ]
    },
    {
        path: "/live-yoga",
        component: () => import("../views/live/LiveWrapper.vue"),
        children: [
            {
                path: "",
                name: "liveYoga",
                component: () => import("../views/live/Main.vue")
            }
        ]
    },
    {
        path: "/predplatne",
        component: () => import("../views/subscription/SubscriptionWrapper.vue"),
        children: [
            {
                path: ":slug",
                name: "inactiveSubscription",
                component: () => import("../views/subscription/Inactive.vue")
            }
        ]
    },
    {
        path: "/o-mne",
        name: "aboutMe",
        component: () => import("../views/AboutMe.vue")
    },
    {
        path: "/konto",
        component: () => import("../views/account/AccountWrapper.vue"),
        children: [
            {
                path: "prihlasenie",
                name: "login",
                component: () => import("../views/account/Login.vue")
            },
            {
                path: "zabudnute-heslo",
                name: "lostPassword",
                component: () => import("../views/account/LostPassword.vue")
            },
            {
                path: "obnova-hesla/:id/:slug",
                name: "resetPassword",
                component: () => import("../views/account/ResetPassword.vue")
            },
            {
                path: "registracia",
                name: "register",
                component: () => import("../views/account/Register.vue")
            },
            {
                path: "ucet",
                name: "account",
                component: () => import("../views/account/Account.vue")
            },
            {
                path: "zmenit-heslo",
                name: "changePassword",
                component: () => import("../views/account/ChangePassword.vue")
            },
            {
                path: "faktury",
                name: "invoices",
                component: () => import("../views/account/Invoices.vue")
            },
            {
                path: "predplatne",
                name: "subscription",
                component: () => import("../views/account/Subscription.vue")
            },
        ]
    },
    {
        path: "/faq",
        name: "faq",
        component: () => import("../views/Faq.vue")
    },
    {
        path: "/obchodne-podmienky",
        name: "termsOfUse",
        component: () => import("../views/TermsOfUse.vue")
    },
    {
        path: "/reklamacne-podmienky",
        name: "returnPolicy",
        component: () => import("../views/TermsOfUse.vue")
    },
    {
        path: "/gdpr",
        name: "gdpr",
        component: () => import("../views/Gdpr.vue")
    },
    {
        path: "/joga-vybavenie",
        name: "equipment",
        component: () => import("../views/EquipmentPage.vue")
    },
    {
        path: "/coskoro",
        name: "comingSoon",
        component: () => import("../views/ComingSoon.vue"),
        children: [
            {
                path: "ja-mozem",
                name: "unlock",
                component: () => import("../views/ComingSoon.vue")
            }
        ]
    },
    {
        path: "*",
        component: () => import("../views/NotFound.vue")
        // redirect: {
        //     name: 'homepage'
        // }
    }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        return {x: 0, y: 0};
    }
});

export default router;

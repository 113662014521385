import api from "./../api";

export default {
    namespaced: true,
    state: {
        loadingInvoices: true,
        invoices: []
    },
    getters: {},
    actions: {
        list(store: any) {
            store.state.invoices = [];
            store.state.loadingInvoices = true;
            api
                .get("invoice/my-invoices")
                .then((response: any) => {
                    store.state.invoices = response.data.items;
                    store.state.loadingInvoices = false;
                })
                .catch(() => {
                    store.state.invoices = null;
                    store.state.loadingInvoices = false;
                });
        }
    },
    mutations: {}
};
